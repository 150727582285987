import { render, staticRenderFns } from "./InvoiceForm.vue?vue&type=template&id=5dd57a1b&scoped=true&"
import script from "./InvoiceForm.vue?vue&type=script&lang=ts&"
export * from "./InvoiceForm.vue?vue&type=script&lang=ts&"
import style0 from "./InvoiceForm.vue?vue&type=style&index=0&id=5dd57a1b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dd57a1b",
  null
  
)

export default component.exports